.drive_buttons_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.file_list_item {
  height: 26px;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .drive_buttons_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 20px;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}